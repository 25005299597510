import { render, staticRenderFns } from "./ReceiptsModal.vue?vue&type=template&id=012ea454&scoped=true&"
import script from "./ReceiptsModal.vue?vue&type=script&lang=js&"
export * from "./ReceiptsModal.vue?vue&type=script&lang=js&"
import style0 from "./ReceiptsModal.vue?vue&type=style&index=0&id=012ea454&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012ea454",
  null
  
)

export default component.exports