<template>
  <!-- Receipts Modal -->
  <b-modal id="receiptsModal">
    <template slot="modal-title" class="mb-0">
      <h4 class="font-weight-bold">
        My Receipt
      </h4>
    </template>
    <p v-if="_.isEmpty(receipts)">No receipt to display.</p>
    <div
      v-for="(items, payment_intent, index) in receipts"
      :key="'receipt-' + payment_intent"
      class="mb-5"
    >
      <h6 class="font-weight-bold">
        Transaction #{{ index + 1 }}
        <a class="float-right font-weight-normal "
          >{{ items.length }} item<span v-if="items.length > 1">s</span></a
        >
      </h6>
      <OrderItem
        v-for="item in items"
        v-bind:item="item"
        v-bind:key="'orderitem-' + item.id"
      ></OrderItem>
      <p class="mt-0 mb-2 small">
        Transaction ID: {{ payment_intent | formatIntent }} <br />
        Order ID: {{ $store.state.order.uuid }} <br />
        Device ID: {{ $cookies.get("X-DEVICE-ID") }}
      </p>
    </div>
    <template slot="modal-footer">
      <button class="btn primary-blue" v-on:click="close">
        <span class="font-weight-bold">Close</span>
      </button>
    </template>
  </b-modal>
</template>

<script>
import OrderItem from "../components/OrderItem.vue";
export default {
  name: "ReceiptsModal",
  props: ["items"],
  components: {
    OrderItem,
  },
  data: function() {
    return {
      loading: false,
    };
  },
  methods: {
    close: function() {
      this.$bvModal.hide("receiptsModal");
    },
    sendReceipt: function() {},
  },
  computed: {
    receipts: function() {
      var device = this.$cookies.get("X-DEVICE-ID");
      return this._.groupBy(
        this._.pickBy(this.items, function(item) {
          return item.payment.status == 3 && item.payment.device == device;
        }),
        "payment.intent_id"
      );
    },
  },
  filters: {
    formatIntent: function(intent) {
      return intent.slice(3);
    },
  },
};
</script>
<style scoped>
.badge {
  vertical-align: top;
}
.modal-content {
  margin-top: 0;
}
.spinner-grow {
  background: white !important;
}
.modal-footer {
  display: block !important;
}
</style>
