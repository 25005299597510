<template>
  <!-- Payment -->
  <b-container fluid>
    <PaymentNav :items="items"></PaymentNav>
    <PaymentForm
      :items="validItems"
      :preSelectedItems="myItems"
      v-if="validItems.length"
    ></PaymentForm>
    <PaymentStatus :display="!validItems.length"></PaymentStatus>
    <ReceiptsModal :items="items"></ReceiptsModal>
  </b-container>
</template>

<script>
import PaymentNav from "../components/PaymentNav.vue";
import PaymentForm from "../components/PaymentForm.vue";
import PaymentStatus from "../components/PaymentStatus.vue";
import ReceiptsModal from "../components/ReceiptsModal.vue";

export default {
  name: "Payment",
  components: {
    PaymentNav,
    PaymentForm,
    PaymentStatus,
    ReceiptsModal,
  },
  computed: {
    items: function() {
      return this._.flatMap(this.$store.state.order.tickets, function(obj) {
        return obj.items;
      });
    },
    validItems: function() {
      return this._.filter(this.items, function(item) {
        return item.payment.status == 1 && item.status >= 2;
      });
    },
    myItems: function() {
      // eslint-disable-next-line no-unused-vars
      var device = this.$cookies.get("X-DEVICE-ID");
      console.log(device);
      return this._.flatMap(
        this._.pickBy(this.validItems, function(item) {
          return item.device == device;
        }),
        function(object) {
          return object;
        }
      );
    },
  },
};
</script>
