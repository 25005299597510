<template>
  <!-- Payment Status -->
  <b-row no-gutters v-if="display" style="min-height:65vh;">
    <b-col class="my-auto text-center">
      <h3 class="font-weight-bold mt-4">
        Payment Complete
      </h3>
      <button
        class="font-weight-bold btn ml-auto mt-3 primary-blue"
        v-b-modal="'receiptsModal'"
      >
        My Reciept
      </button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "PaymentStatus",
  props: ["display"],
};
</script>
