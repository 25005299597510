<template>
  <!-- Nav -->
  <b-row no-gutters>
    <b-col>
      <nav id="nav" class="navbar navbar-light mt-1 mb-1">
        <router-link
          class="font-weight-bold btn mr-auto primary-blue"
          to="/order/status/"
          >Back</router-link
        >
        <button
          class="font-weight-bold btn ml-auto primary-blue"
          v-b-modal="'receiptsModal'"
        >
          My Reciept
        </button>
      </nav>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "PaymentNav",
  props: ["items", "displayReciepts"],
  data: function() {
    return {
      display: false,
      loading: false,
    };
  },
  computed: {},
};
</script>
