<template>
  <!-- Payment Form -->
  <b-row no-gutters style="min-height:75vh;">
    <b-col class="text-center my-auto">
      <h3 class="font-weight-bold mb-4 mt-4">Pay Now</h3>
      <!-- Select Items -->
      <li class="list-group-item d-flex justify-content-between pl-0 pt-0">
        <span class="font-weight-bold">Items </span>
        <span class=""> {{ selected.length }} Selected</span>
      </li>
      <b-form-checkbox
        v-for="item in items"
        v-model="selected"
        :key="'payment-option-' + item.id"
        :value="item"
        :aria-describedby="item.item_name"
        class="text-left mb-2"
        size="lg"
      >
        {{ item.item_name }}
        <span class="badge font-weight-bold primary-blue mr-1">{{
          item.quantity
        }}</span>
      </b-form-checkbox>
      <hr />
      <form id="payment-form">
        <!-- Payment Type -->
        <li class="list-group-item d-flex justify-content-between pl-0 pt-0">
          <span class="font-weight-bold">Payment Type</span>
        </li>
        <b-tabs
          pills
          nav-class="font-weight-bold mb-3"
          :justified="true"
          active-nav-item-class="active-btn primary-blue shadow-sm"
        >
          <b-tab title="Card" @click="type = 1">
            <div ref="card"></div>
            <div id="card-errors" role="alert" class="small font-weight-bold">
              {{ this.errors }}
            </div>
          </b-tab>
          <b-tab title="Cash" @click="type = 2"></b-tab>
        </b-tabs>
        <!-- Payment Info -->
        <hr />
        <li class="list-group-item d-flex justify-content-between pl-0 pt-0">
          <span class="font-weight-bold">Tip</span>
          <span id="subtotal">${{ tip | formatDollar }}</span>
        </li>
        <b-tabs
          pills
          nav-class="font-weight-bold mb-3"
          :justified="true"
          active-nav-item-class="active-btn primary-blue shadow-sm"
        >
          <b-tab title="18 %" @click="setTip(0.18)"></b-tab>
          <b-tab title="15 %" @click="setTip(0.15)"></b-tab>
          <b-tab title="Custom">
            <b-card-text>
              <b-form-input
                id="range-2"
                v-model="tip_percentage"
                type="range"
                min="0"
                max="1"
                step="0.02"
              ></b-form-input>
              <p class="small text-muted">
                Tip %: {{ (tip_percentage * 100) | formatPercentage }}
              </p>
            </b-card-text></b-tab
          >
        </b-tabs>
        <hr />
        <li class="list-group-item d-flex justify-content-between pl-0">
          <span class="font-weight-bold">Subtotal</span>
          <span id="subtotal">${{ subtotal | formatDollar }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between pl-0">
          <span class="font-weight-bold">Taxes</span>
          <span id="tax">${{ tax | formatDollar }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between pl-0">
          <span class="font-weight-bold">Total</span>
          <span id="total">${{ total | formatDollar }}</span>
        </li>
        <!-- Submit Payment -->
        <button
          class="font-weight-bold btn primary-blue"
          v-on:click.prevent="submitPayment"
          v-if="total > 0"
          :disabled="loading"
        >
          <span v-if="!this.loading">
            Pay ${{ this.total | formatDollar }}
          </span>
          <b-spinner
            small
            v-if="this.loading"
            type="grow"
            style="vertical-align:middle;"
          ></b-spinner>
        </button>
      </form>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "PaymentForm",
  props: ["items", "preSelectedItems"],
  data: function() {
    return {
      tip_percentage: 0.18,
      selected: this.preSelectedItems,
      type: 1,
      pk: "pk_test_7GI8zzwhwzW8r6AZAV5J7Tvx00bb1ZRp5X",
      stripe: undefined,
      card: undefined,
      loading: false,
      errors: "",
    };
  },
  methods: {
    setTip: function(percentage) {
      console.log(percentage);
      this.tip_percentage = percentage;
    },
    handleAuthResponse: function(response) {
      if (response.error) {
        this.loading = false;
        this.errors = response.error.message;
        this.$forceUpdate();
      } else {
        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        this.axios
          .post(process.env.VUE_APP_CARD_PAYMENT_URL, {
            vendor: this.$store.state.vendor.uuid,
            payment_intent_id: response.paymentIntent.id,
          })
          .then((result) => {
            // Handle server response (see Step 4)
            this.handleServerResponse(result);
          })
          .catch((error) => {
            console.log(error);
            this.errors = error.message;
            this.loading = false;
          });
      }
    },
    handleServerResponse: function(response) {
      if (response.error) {
        this.errors = response.error.message;
        this.loading = false;
        this.$forceUpdate();
      } else if (response.data.requires_action) {
        // Use Stripe.js to handle required card action
        console.log(response.data.payment_intent_client_secret);
        this.stripe
          .handleCardAction(response.data.payment_intent_client_secret)
          .then((result) => {
            this.handleAuthResponse(result);
          });
      } else {
        // Show success message by redirecting to reciepts page
        this.loading = false;
        this.errors = "";
        //Open order success modal with email field for reciept email
        console.log("Worked");
      }
    },
    paymentMethodHandler: function(response) {
      if (response.error) {
        // Show error in payment form
        this.errors = response.error.message;
        this.$forceUpdate();
        this.loading = false;
      } else {
        // Otherwise send paymentMethod.id to your server (see Step 4)
        this.axios
          .post(process.env.VUE_APP_CARD_PAYMENT_URL, {
            vendor: this.$store.state.vendor.uuid,
            payment_method_id: response.paymentMethod.id,
            tip: this.tip_percentage,
            selected: this.selectedIds,
          })
          .then((result) => {
            // Handle server response (see Step 4)
            this.handleServerResponse(result);
          })
          .catch((error) => {
            console.log(error);
            this.errors = error.message;
            this.loading = false;
          });
      }
    },
    submitPayment: function() {
      this.loading = true;
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.card,
          metadata: {
            tip_percentage: this.tip_percentage,
            order: this.$store.state.order.uuid,
            device: this.$cookies.get("X-DEVICE-ID"),
            //selected: this.selected,
          },
        })
        .then((response) => {
          this.paymentMethodHandler(response);
        });
    },
  },
  mounted: function() {
    this.stripe = window.Stripe(this.pk);
    var style = {
      base: {
        fontSize: "16px",
        fontFamily: '"Montserrat", sans-serif',
      },
    };
    this.card = this.stripe.elements().create("card", { style: style });
    this.card.mount(this.$refs.card);
  },
  computed: {
    subtotal: function() {
      return this._.sumBy(this.selected, function(item) {
        return parseFloat(item.total);
      });
    },
    tip: function() {
      return parseFloat(this.subtotal * this.tip_percentage);
    },
    tax: function() {
      return parseFloat(
        this.subtotal * this.$store.state.vendor.tax_percentage
      );
    },
    total: function() {
      return parseFloat(this.subtotal + this.tax + this.tip);
    },
    selectedIds: function() {
      return this._.map(this.selected, "id");
    },
  },
  filters: {
    formatDollar: function(number) {
      return number.toFixed(2);
    },
    formatPercentage: function(number) {
      return number.toFixed(0);
    },
  },
};
</script>
<style>
li {
  background-color: white !important;
  color: black;
  border-color: white !important;
}
a {
  color: black !important;
}
.active-btn {
  color: white !important;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: rgb(80, 72, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5048ff",endColorstr="#3695ff",GradientType=1);
  border: none !important;
  cursor: pointer;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  background: rgb(80, 72, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5048ff",endColorstr="#3695ff",GradientType=1);
  border: none !important;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  background: rgb(80, 72, 255);
  background: -moz-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(80, 72, 255, 1) 0%,
    rgba(54, 149, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5048ff",endColorstr="#3695ff",GradientType=1);
  border: none !important;
  cursor: pointer;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: rgb(80, 72, 255);
  border-radius: 0.25rem;
}
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.StripeElement--invalid {
  border-color: #fa755a;
}
#card-errors {
  color: #eb1c26;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.spinner-grow {
  background: white !important;
}
</style>
